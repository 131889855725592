<template>
  <div class="active-slides">
    <div class="active-slides__header">
      <h2 class="active-slides__heading">
        {{ $t("pages.slides.heading.organization") }}
      </h2>
      <Button
        theme="secondary"
        class="active-slides__create-btn"
        @click="showCreateModal = true"
      >
        <inline-svg
          class="active-slides__create-btn__icon"
          :src="require(`../../assets/icons/Plus.svg`)"
          aria-label="Create"
        ></inline-svg>
        {{ $t("pages.slides.createButton") }}
      </Button>
    </div>
    <SlideFilter
      v-if="slides && slides.length > 0"
      class="active-slides__filter"
      :day-filter="filter.day"
      @day="onDayFilterChange"
      :type-filter="filter.type"
      @type="onTypeFilterChange"
      :tag-filter="filter.tag"
      @tag="onTagFilterChange"
    ></SlideFilter>

    <draggable
      :list="slides"
      class="active-slides__list"
      v-if="slides && !isMobile"
      ghost-class="active-slides__item--ghost"
      @end="updateList"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="active-slides__item-wrap"
      >
        <div
          @click="openEditor(slide)"
          v-if="showActiveSlide(slide)"
          class="active-slides__item"
        >
          <Slide :slide="slide"></Slide>
        </div>
      </div>
    </draggable>

    <div class="active-slides__list" v-if="slides && isMobile">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="active-slides__item-wrap"
      >
        <div
          @click="openEditor(slide)"
          v-if="showActiveSlide(slide)"
          class="active-slides__item"
        >
          <Slide :slide="slide"></Slide>
        </div>
      </div>
    </div>

    <Modal
      class="active-slides__modal"
      v-if="showCreateModal"
      @close="showCreateModal = false"
      :heading="$t('components.create.heading')"
    >
      <div slot="modal-content">
        <Create
          :show-heading="false"
          class="active-slides__modal-content"
        ></Create>
      </div>
    </Modal>
  </div>
</template>

<script>
import Slide from "@/components/Slide";
import Button from "@/components/Button";
import SlideFilter from "@/components/SlideFilter";
import Modal from "@/components/Modal";
import Create from "@/components/Create";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  components: {
    Slide,
    SlideFilter,
    Modal,
    Create,
    Button,
    draggable
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showCreateModal: false,
    filter: {
      day: "allWeek",
      type: "allTypes",
      tag: {
        name: "allTags"
      }
    }
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    ...mapGetters("teams", {
      currentTeam: "getCurrentTeam"
    }),
    slides() {
      if (this.currentTeam) {
        return this.currentTeam.slides;
      }

      return [];
    },
    filteredSlides() {
      let slides = this.slides.filter(item => item !== undefined);
      slides = slides.filter(slide => slide.status === "published");
      if (this.filter.day !== "allWeek") {
        slides = slides.filter(slide => {
          let days = slide.days.filter(day => {
            return day.name === this.filter.day && day.active;
          });

          return days.length > 0 ? true : false;
        });
      }

      if (this.filter.type !== "allTypes") {
        slides = slides.filter(slide => {
          return slide.type === this.filter.type;
        });
      }

      if (this.filter.tag.name !== "allTags") {
        let tags = [];
        if (this.filter.tag.type === "screen") {
          tags = this.filter.tag.tags.map(tag => {
            return tag.id;
          });
        } else if (this.filter.tag.type === "tag") {
          tags.push(this.filter.tag.id);
        }

        slides = slides.filter(slide => {
          if (slide.tags) {
            let slideTags = slide.tags.map(tag => tag.id);
            let hasTag = slideTags?.some(v => tags.includes(v));
            return hasTag;
          } else {
            return true;
          }
        });
      }

      return slides.map(slide => slide.id);
    }
  },
  methods: {
    ...mapActions("slides", ["updateSlideOrder", "setOriginPage"]),
    showActiveSlide(slide) {
      if (!slide?.id) {
        return false;
      }
      return (
        this.filteredSlides.includes(slide.id) && this.slideIsNotBroken(slide)
      );
    },
    slideIsNotBroken(slide) {
      if (slide.type === "image") {
        return !!slide.url.image?.landscape || !!slide.url.image?.portrait;
      } else if (slide.type === "video") {
        return !!slide.url.video?.landscape || !!slide.url.video?.portrait;
      } else {
        return true;
      }
    },
    checkParentTemplate(slide) {
      if (slide.template.isParentTemplate) {
        return "1";
      } else if (slide.template.isRootTemplate) {
        return "2";
      } else if (slide.template.isLobbySpaceTemplate) {
        return "3";
      } else {
        return "0";
      }
    },
    openEditor(slide) {
      this.setOriginPage(this.$route.path);

      if (slide.type === "template") {
        this.$router.push({
          name: "Editor",
          params: {
            file: slide.type,
            id: slide.id,
            isParent: this.checkParentTemplate(slide),
            templateId: slide.template.id
          }
        });
      } else {
        this.$router.push({
          name: "Editor",
          params: {
            file: slide.type,
            id: slide.id
          }
        });
      }
    },
    onDayFilterChange(day) {
      this.filter.day = day;
    },
    onTypeFilterChange(type) {
      this.filter.type = type;
    },
    onTagFilterChange(tag) {
      this.filter.tag = tag;
    },
    updateList() {
      this.updateSlideOrder(this.slides);
    }
  }
};
</script>

<style lang="scss" scoped>
.active-slides {
  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    align-self: flex-start;
  }

  &__filter {
    margin-bottom: 28px;
  }

  &__create-btn {
    align-self: flex-end;

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &__modal-content {
    border: none;
    max-width: 810px;
    overflow-y: auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item-wrap {
    @apply w-full cursor-pointer;

    &--ghost {
      @apply opacity-50 bg-ls-gray-300 rounded;
    }
  }
}

@media screen and (min-width: 900px) {
  .active-slides {
    &__header {
      flex-direction: row;
    }

    &__heading {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 2rem;
      margin-top: 0;
    }

    &__create-btn {
      align-self: flex-start;
    }

    &__item-wrap {
      @apply w-auto;
    }

    &__item {
      @apply w-auto mr-4 mb-4;
    }
  }
}
</style>
