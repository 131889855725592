<template>
  <div class="root-slides">
    <div class="root-slides__header">
      <h2 class="root-slides__heading" v-if="rootTeam">
        {{ $t("pages.slides.heading.headquarter") }} {{ rootTeam.name }}
      </h2>
    </div>

    <SlideFilter
      v-if="slides && slides.length > 0"
      class="root-slides__filter"
      :day-filter="filter.day"
      @day="onDayFilterChange"
      :type-filter="filter.type"
      @type="onTypeFilterChange"
      :is-root="true"
      :tag-filter="filter.tag"
      @tag="onTagFilterChange"
    ></SlideFilter>

    <div class="root-slides__list" v-if="slides">
      <div
        class="root-slides__item"
        v-for="(slide, slidesIndex) in filteredSlides"
        :key="slidesIndex"
      >
        <div v-if="showSlide(slide)">
          <Slide :slide="slide"></Slide>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slide from "@/components/Slide";
import SlideFilter from "@/components/SlideFilter";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Slide,
    SlideFilter
  },
  data: () => ({
    filter: {
      day: "allWeek",
      type: "allTypes",
      tag: {
        name: "allTags"
      }
    }
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      rootTeam: "getRootTeam"
    }),
    slides() {
      if (this.rootTeam) {
        return this.rootTeam.slides;
      }

      return [];
    },
    filteredSlides() {
      let slides = this.slides.filter(item => item !== undefined);
      slides = slides.filter(slide => slide.status === "published");
      slides = slides.filter(slide => {
        let branch = null;
        branch = slide.branches.filter(branch => {
          if (branch.id === this.team.id && branch.active) {
            return branch;
          }
        });

        if (branch && branch.length > 0) {
          return slide;
        }
      });

      if (this.filter.day !== "allWeek") {
        slides = slides.filter(slide => {
          let days = slide.days.filter(day => {
            return day.name === this.filter.day && day.active;
          });

          return days.length > 0 ? true : false;
        });
      }

      if (this.filter.type !== "allTypes") {
        slides = slides.filter(slide => {
          return slide.type === this.filter.type;
        });
      }

      if (this.filter.tag.name !== "allTags") {
        let tags = [];
        if (this.filter.tag.type === "screen") {
          tags = this.filter.tag.tags.map(tag => {
            return tag.id;
          });
        } else if (this.filter.tag.type === "tag") {
          tags.push(this.filter.tag.id);
        }

        slides = slides.filter(slide => {
          if (slide.tags) {
            let slideTags = slide.tags.map(tag => tag.id);
            let hasTag = slideTags?.some(v => tags.includes(v));
            return hasTag;
          } else {
            return true;
          }
        });
      }

      return slides;
    }
  },
  mounted() {
    this.fetchRootTeam(this.team.rootTeam);
  },
  methods: {
    ...mapActions("teams", ["fetchRootTeam"]),
    showSlide(slide) {
      if (!slide?.id) {
        return false;
      }
      return this.slideIsNotBroken(slide);
    },
    slideIsNotBroken(slide) {
      if (slide.type === "image") {
        return !!slide.url.image?.landscape || !!slide.url.image?.portrait;
      } else if (slide.type === "video") {
        return !!slide.url.video?.landscape || !!slide.url.video?.portrait;
      } else {
        return true;
      }
    },
    onDayFilterChange(day) {
      this.filter.day = day;
    },
    onTypeFilterChange(type) {
      this.filter.type = type;
    },
    onTagFilterChange(tag) {
      this.filter.tag = tag;
    }
  }
};
</script>

<style lang="scss" scoped>
.root-slides {
  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    align-self: flex-start;
  }

  &__filter {
    margin-bottom: 28px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 1rem;
    min-width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .root-slides {
    &__header {
      flex-direction: row;
    }

    &__heading {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 2rem;
      margin-top: 0;
    }

    &__item {
      margin-right: 1rem;
      min-width: unset;
    }
  }
}
</style>
