var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slides"},[_c('div',{staticClass:"slides__side-bar"},[_c('div',{staticClass:"slides__side-bar-list-header"},[_vm._v(" "+_vm._s(_vm.$t("pages.slides.teamSlides"))+" ")]),_vm._l((_vm.ownSlides),function(item,index){return _c('div',{key:index,staticClass:"slides__side-bar-item"},[_c('router-link',{staticClass:"slides__side-bar-link",class:{
          'slides__side-bar-link--active': _vm.$route.path === item.link
        },attrs:{"to":item.link}},[_c('inline-svg',{staticClass:"slides__side-bar-link-icon",attrs:{"src":require(`../../assets/icons/${item.icon}.svg`),"aria-label":item.icon}}),_vm._v(" "+_vm._s(_vm.$t(`pages.slides.${item.name}`))+" ")],1)],1)}),(_vm.parentTeam)?_c('div',[_c('div',{staticClass:"slides__divider"}),_c('div',{staticClass:"slides__side-bar-list-header"},[_vm._v(" "+_vm._s(_vm.$t("pages.slides.subscriptionSlides"))+" ")]),_c('div',{staticClass:"slides__side-bar-item"},[_c('router-link',{staticClass:"slides__side-bar-link",class:{
            'slides__side-bar-link--active':
              _vm.$route.path === '/slides/headquarter'
          },attrs:{"to":"/slides/headquarter"}},[(_vm.parentTeam.avatar)?_c('div',{staticClass:"slides__chosen-logo",style:({
              'background-image': 'url(' + _vm.parentTeam.avatar + ')'
            })}):_c('ReplacementLogo',{staticClass:"slides__chosen-logo--unset",attrs:{"name":_vm.parentTeam.name}}),_vm._v(" "+_vm._s(_vm.parentTeam.name)+" ")],1)],1),(_vm.showRootTeam)?_c('div',{staticClass:"slides__side-bar-item"},[_c('router-link',{staticClass:"slides__side-bar-link",class:{
            'slides__side-bar-link--active': _vm.$route.path === '/slides/root'
          },attrs:{"to":"/slides/root"}},[(_vm.rootTeam.avatar)?_c('div',{staticClass:"slides__chosen-logo",style:({
              'background-image': 'url(' + _vm.rootTeam.avatar + ')'
            })}):_c('ReplacementLogo',{staticClass:"slides__chosen-logo--unset",attrs:{"name":_vm.rootTeam.name}}),_vm._v(" "+_vm._s(_vm.rootTeam.name)+" ")],1)],1):_vm._e()]):_vm._e()],2),_c('SideBarMobile',{staticClass:"slides__side-bar--mobile",attrs:{"list":_vm.slideLinksWithoutIcons}}),_c('div',{staticClass:"slides__wrapper"},[_c(_vm.componentName,{tag:"component",staticClass:"slides__content",attrs:{"is-mobile":_vm.isMobile}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }