<template>
  <div class="slide-filter">
    <DropDown class="slide-filter__filter" v-model="day" :list="days">
      <template slot="selected">
        <div class="slide-filter__day">
          {{ $t(`dropdown.${day}`) }}
        </div>
      </template>
    </DropDown>

    <DropDown class="slide-filter__filter" v-model="type" :list="types">
      <template slot="selected">
        <div class="slide-filter__type">
          {{ $t(`dropdown.${type}`) }}
        </div>
      </template>
    </DropDown>

    <DropDown
      class="slide-filter__filter slide-filter__filter--tags"
      v-model="tag"
      :list="tags"
      v-if="tags.length > 0"
      :translation="false"
      item-value="name"
    >
      <template slot="selected">
        <span v-if="tag.name === 'allTags'">
          {{ $t("dropdown.allTags") }}
        </span>
        <span v-else>{{ tag.name }}</span>
      </template>
    </DropDown>
  </div>
</template>

<script>
import DropDown from "@/components/DropDown";
import { mapGetters } from "vuex";
export default {
  components: {
    DropDown
  },
  props: {
    dayFilter: {
      type: String,
      default: "allWeek"
    },
    typeFilter: {
      type: String,
      default: "allTypes"
    },
    tagFilter: {
      type: Object,
      default: () => {}
    },
    isHeadquarter: {
      type: Boolean,
      default: false
    },
    isRoot: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    day: "",
    days: [
      "allWeek",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday"
    ],
    type: "",
    types: ["allTypes", "image", "video", "template", "webview"],
    tag: ""
  }),
  mounted() {
    this.day = this.dayFilter;
    this.type = this.typeFilter;
    this.tag = this.tagFilter;
  },
  watch: {
    day() {
      this.$emit("day", this.day);
    },
    type() {
      this.$emit("type", this.type);
    },
    tag() {
      this.$emit("tag", this.tag);
    }
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      rootTeam: "getRootTeam"
    }),
    parentTags() {
      if (this.parentTeam && this.parentTeam.tags) {
        return this.parentTeam.tags;
      }

      return {};
    },
    rootTags() {
      if (this.rootTeam && this.rootTeam.tags) {
        return this.rootTeam.tags;
      }

      return {};
    },
    tags() {
      let tagArray = [];
      tagArray.push({
        name: "allTags"
      });

      if (this.isHeadquarter) {
        let tempTags = Object.entries(this.parentTags).map(([id, value]) => ({
          type: "tag",
          id,
          name: value.name
        }));

        tempTags.forEach(tag => {
          tagArray.push(tag);
        });

        return [...new Set(tagArray)];
      }

      if (this.isRoot) {
        let tempTags = Object.entries(this.rootTags).map(([id, value]) => ({
          type: "tag",
          id,
          name: value.name
        }));

        tempTags.forEach(tag => {
          tagArray.push(tag);
        });

        return [...new Set(tagArray)];
      }

      let nameTags = this.team.screens.map(screen => {
        let screenTags = [];
        screenTags.push({
          id: String(screen.id)
        });

        if (screen.tags) {
          screenTags = screenTags.concat(screen.tags);
        }
        return {
          type: "screen",
          name: screen.name,
          tags: screenTags
        };
      });

      let tempTags = [];

      if (this.team.tags) {
        tempTags = Object.entries(this.team.tags).map(([id, value]) => ({
          type: "tag",
          id,
          name: value.name
        }));
        tempTags = nameTags.concat(tempTags);
      } else {
        tempTags = nameTags;
      }

      if (this.hasParentTeam && Object.keys(this.parentTags).length > 0) {
        let parentTags = Object.entries(this.parentTags).map(([id, value]) => ({
          type: "tag",
          id,
          name: value.name
        }));

        tempTags = nameTags.concat(parentTags);
      }

      if (this.hasRootTeam && Object.keys(this.rootTags).length > 0) {
        let rootTags = Object.entries(this.rootTags).map(([id, value]) => ({
          type: "tag",
          id,
          name: value.name
        }));

        tempTags = nameTags.concat(rootTags);
      }

      tempTags.forEach(tag => {
        tagArray.push(tag);
      });

      return [...new Set(tagArray)];
    },
    hasParentTeam() {
      return !!this.team?.parentTeam;
    },
    hasRootTeam() {
      return (
        !!this.team?.rootTeam && this.team.rootTeam !== this.team.parentTeam
      );
    }
  }
};
</script>

<style lang="scss">
.slide-filter {
  display: flex;

  &__filter {
    @apply text-xs font-semibold text-ls-gray-800 flex items-center mr-6;
    width: fit-content;

    &--tags {
      .dropdown__option,
      .dropdown__selected-wrapper {
        text-transform: capitalize;
      }
    }
  }

  &__day,
  &__screen,
  &__type {
    @apply mr-2;
  }

  &__tag {
    text-transform: capitalize;
  }

  .dropdown__selected-wrapper {
    border: none;
    padding: 0;
  }

  .dropdown__wrapper {
    top: 20px;
  }
}
</style>
